import React from "react"
import "./dashboardMobile.css"



function DashboardMobile() {



    return (
        <div className="mobileapp">
            <div className="atf-main-header">
                <div className="container-fluid">
                    <div className="atf-main-header-in">
                        <div className="atf-main-header-left">
                            <a className="atf-site-branding atf-white-logo" href="https://gokeywe.com" rel="noreferrer"><img src="/images/goKeyweLogoNew.png"
                                alt="Logo" /></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="atf-slider atf-style1 atf-hero-slider1 atf-hero-slider2">
                <div className="slick-container" data-autoplay="0" data-loop="1" data-speed="800"
                    data-autoplay-timeout="1000" data-center="0" data-slides-per-view="1" data-fade-slide="1">
                    <div className="slick-wrapper">
                        <div className="slick-slide-in">
                            <div className="atf-single-home atf-hero-area"
                                style={{ backgroundImage: "none", backgroundSize: "cover", backgroundPosition: "center" }}>
                                <div className="atf-home-overlay video-part">
                                    <img src="/images/mobilebanner.png" alt="mobilebanner" className="mobile-banner" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pagination atf-style1 container"></div>

                    {/* <div class="swipe-arrow atf-style1 atf-hidden">
                        <div class="slick-arrow-left"><i class="fa fa-angle-left"></i></div>
                        <div class="slick-arrow-right"><i class="fa fa-angle-right"></i></div>
                    </div> */}
                </div>
            </div>

            <div className="mobile-section" id="sectionone">
                <div className="container">
                    <div className="row section-vertical-middle">
                        <div className="col-xl-12 col-lg-12 col-12 text-center">
                            <div className="mobile-header-title">
                                <h2 className="findText">Find your <br /> perfect home </h2>
                                <div className="mobile-button pt-3"><a
                                    href="https://apps.apple.com/us/app/keywe-go/id6443581681" target="_blank" rel="noreferrer"><img
                                        src="/images/app.png" alt="appstore" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.sdi.keywe" target="_blank" rel="noreferrer"><img
                                        src="/images/google.png" alt="googleimage" /></a>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div className="mobile-section2 mt-5" id="sectionone">
                <div className="container">
                    <div className="row section-vertical-middle">
                        <div className="col-xl-12 col-lg-12 col-12 text-center">
                            <div className="mobile-header-content">
                                <h2>Your new home is<br /> waiting for you </h2>
                                <div className="mobile-image py-4">
                                    <img src="/images/mobilesection2.png" alt="mobileImage" className="w-100"/>
                                </div>
                                <p className="findText">Tap into active listings as well as broker and off-market exclusives</p>
                            </div>

                        </div>

                    </div>

                </div>
            </div>

            <div className="mobile-section2 mt-5" id="sectionone">
                <div className="container">
                    <div className="row section-vertical-middle">
                        <div className="col-xl-12 col-lg-12 col-12 text-center">
                            <div className="mobile-header-content">
                                <h2>Stay Connected </h2>
                                <div className="mobile-image py-4">
                                    <img src="/images/mobilesection3.png" alt="mobilesection" className="w-100"/>
                                </div>
                                <p>Message your Agent anytime, anywhere</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mobile-section2 my-5" id="sectionone">
                <div className="container">
                    <div className="row section-vertical-middle">
                        <div className="col-xl-12 col-lg-12 col-12 text-center">
                            <div className="mobile-header-content">
                                <h2>Schedule Tours and Submit Offers</h2>
                                <div className="mobile-image py-4">
                                    <img src="/images/mobilesection4.png" alt="mobilesection" className="w-100"/>
                                </div>
                                <p>Effortlessly set up and manage property tours as well as make offers using the KeyWe app!</p>
                                <div className="atf-main-btn mt-4 text-left">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mobile-section mb-6 mt-0" id="sectionone">
                <div className="container">
                    <div className="row section-vertical-middle">
                        <div className="col-xl-12 col-lg-12 col-12 text-center">
                            <div className="mobile-header-title">
                                <p>Designed to be the only Real Estate tool you’ll ever need. Get started today!</p>
                                <p className="button-content-mobile">Available for iOS and Android</p>
                                <div className="mobile-button pt-3"><a
                                    href="https://apps.apple.com/us/app/keywe-go/id6443581681"><img
                                        src="/images/app.png" alt="appstore" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.sdi.keywe"><img
                                        src="/images/google.png" alt="playstore" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* //footer// */}
            <footer className="atf-footer-area atf-pattern-area" style={{ border: "0" }}>
                <div className="container-fluid">
                    <div className="atf-footer-top mt-3 footer-mobile">
                        <div className="row">

                            <div className="col-lg-12 col-md-12 col-12 atf-footer-link text-left wow fadeIn mt-2"
                                data-wow-duration="1s" data-wow-delay="0.5s" data-wow-offset="0">
                                <h5>Contact Info</h5>
                                <div className="atf-footer-con mt-3">
                                    <ul className="location-icon">
                                        <li><img src="/images/pin.svg" className="medialoction-img" alt="pin" />Los Gatos,
                                            California</li>
                                        <li><img src="/images/email.svg" className="medialoction-img" alt="email" /><a
                                            href="mailto:info@keywe.com">info@gokeywe.com</a></li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="atf-footer-boottom mt-0 mobile-footer-part">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-xl-12 col-12 my-auto text-lg-center wow fadeIn" data-wow-duration="1s"
                                data-wow-delay="0.2s" data-wow-offset="0">
                                <p className="copysection">
                                    <a href="/" style={{ cursor: "default" }} rel="noreferrer">Copyright © 2024 KeyWe</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>

    )
}
export default DashboardMobile
